import { useState, useEffect, useMemo } from 'react'
import {
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table as ChakraTable,
  Tbody,
  Td,
  Text,
  Tr,
  useDisclosure,
  Box,
  FormControl,
  FormLabel,
  Input,
  Select,
  Button,
  Heading,
  HStack,
  Stack,
  IconButton,
} from '@chakra-ui/react'
import { DatePicker } from 'antd'
import { search } from 'ss-search'
import moment from 'moment/moment'
import Table from 'components/Horizons/table'
import { FaFileExport } from 'react-icons/fa'
import axios from 'axios'
import { httpsCallable } from 'firebase/functions'
import { functions } from 'config/firebase'
import { useQuery } from 'react-query'

const createFileExcelReportEveluation = httpsCallable(
  functions,
  'createReportEvaluation'
)

const getEvaluationReport = httpsCallable(functions, 'getEvaluationReport')

function ModalItems({ onClose, isOpen, data }) {
  const { kid, staff, date, status, enroll, logMember, evaluaton } = data
  const evaluationFee = evaluaton?.payment?.transactions?.reduce(
    (acc, val) => (val?.action === 'paid' ? acc + Number(val.paid) : acc),
    0
  )

  return (
    <Modal
      size='2xl'
      onClose={onClose}
      isOpen={isOpen}
      blockScrollOnMount={true}
    >
      <ModalOverlay />
      <ModalContent mx={4}>
        <ModalHeader fontWeight='normal'></ModalHeader>
        <ModalCloseButton />
        <ModalBody
          w='full'
          p={{ base: '5px', sm: '5px', md: '15px' }}
          maxH='80vh'
          overflowY='auto'
        >
          <Heading size='lg' textAlign='center'>{`${
            kid?.name ? kid?.name : ''
          } ${kid?.nickname ? `(${kid?.nickname})` : ''}`}</Heading>
          <Center
            p='0.5rem'
            w='full'
            border='1px solid #E2E8F0'
            overflowX='auto'
            mt={5}
          >
            <ChakraTable variant='simple' size='md'>
              <Tbody>
                <Tr>
                  <Td fontWeight={600} fontSize='lg'>
                    วันเดือนปีเกิด
                  </Td>
                  <Td fontSize='lg'>
                    {kid?.birthday && kid?.birthday !== ''
                      ? moment(kid?.birthday, 'YYYY-MM-DD').format('DD/MM/YYYY')
                      : ''}
                  </Td>
                </Tr>
                <Tr>
                  <Td fontWeight={600} fontSize='lg'>
                    ผู้ปกครอง
                  </Td>
                  <Td fontSize='lg'>{kid?.parentName1}</Td>
                </Tr>
                <Tr>
                  <Td fontWeight={600} fontSize='lg'>
                    Telephone No.
                  </Td>
                  <Td fontSize='lg'>{kid?.parentTel1}</Td>
                </Tr>
                <Tr>
                  <Td fontWeight={600} fontSize='lg'>
                    Email Address
                  </Td>
                  <Td fontSize='lg'>{kid?.parentEmail1}</Td>
                </Tr>
                <Tr>
                  <Td fontWeight={600} fontSize='lg'>
                    ภาวะอาการ/Dx.
                  </Td>
                  <Td fontSize='lg'>{kid?.development?.diagnosis}</Td>
                </Tr>
                <Tr>
                  <Td fontWeight={600} fontSize='lg'>
                    แพทย์ที่ดูแล
                  </Td>
                  <Td fontSize='lg'>{kid?.development?.doctor}</Td>
                </Tr>
                <Tr>
                  <Td fontWeight={600} fontSize='lg'>
                    รู้จัก Kids Plus จาก
                  </Td>
                  <Td fontSize='lg'>{kid?.from}</Td>
                </Tr>
                <Tr>
                  <Td fontWeight={600} fontSize='lg'>
                    Enrollment
                  </Td>
                  <Td fontSize='lg'>
                    {enroll?.length > 0 ? (
                      enroll.map(
                        (p, i) => `${i > 0 ? ', ' : ''} ${p.program.label}`
                      )
                    ) : (
                      <span style={{ color: 'red' }}>Not Enroll</span>
                    )}
                  </Td>
                </Tr>
                <Tr>
                  <Td fontWeight={600} fontSize='lg'>
                    Programs Price
                  </Td>
                  <Td fontSize='lg'>
                    {enroll?.length > 0 ? (
                      enroll.map(
                        (p, i) =>
                          `${
                            i > 0 ? ', ' : ''
                          } ${p.program.price.toLocaleString()} บาท`
                      )
                    ) : (
                      <span style={{ color: 'red' }}>Not Enroll</span>
                    )}
                  </Td>
                </Tr>
                <Tr>
                  <Td fontWeight={600} fontSize='lg'>
                    Programs Paid
                  </Td>
                  <Td fontSize='lg'>
                    {enroll?.length > 0 ? (
                      enroll.map((p, i) => {
                        const sumPaid = p.payment.reduce(
                          (acc, pay) => acc + pay.paid,
                          0
                        )

                        return `${
                          i > 0 ? ', ' : ''
                        } ${sumPaid.toLocaleString()} บาท`
                      })
                    ) : (
                      <span style={{ color: 'red' }}>Not Enroll</span>
                    )}
                  </Td>
                </Tr>
                <Tr>
                  <Td fontWeight={600} fontSize='lg'>
                    Evaluation Fee
                  </Td>
                  <Td fontSize='lg'>
                    {evaluationFee &&
                      `${Number(evaluationFee).toLocaleString('th-TH')} บาท`}
                  </Td>
                </Tr>
                <Tr>
                  <Td fontWeight={600} fontSize='lg'>
                    Member Apply
                  </Td>
                  <Td fontSize='lg'>
                    {logMember && logMember.isWaive
                      ? 'Member Waive'
                      : logMember && status[0]
                      ? `${Number(
                          status[0]?.payment[0]?.paid || 0
                        ).toLocaleString('th-TH')} บาท`
                      : '-'}
                  </Td>
                </Tr>
                <Tr>
                  <Td fontWeight={600} fontSize='lg'>
                    ผู้ประเมิน
                  </Td>
                  <Td fontSize='lg'>{staff?.nickname}</Td>
                </Tr>
                <Tr>
                  <Td fontWeight={600} fontSize='lg'>
                    สาขา
                  </Td>
                  <Td fontSize='lg'>{staff?.branch}</Td>
                </Tr>
                <Tr>
                  <Td fontWeight={600} fontSize='lg'>
                    วันที่ประเมิน
                  </Td>
                  <Td fontSize='lg'>
                    {moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')}
                  </Td>
                </Tr>
              </Tbody>
            </ChakraTable>
          </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export function Evalution({ data, branches, managerBranchId }) {
  const columnsData = [
    {
      Header: 'วันที่ประเมิน',
      accessor: 'date',
      width: '10%',
      extra: (date) => moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY'),
    },
    {
      Header: 'ชื่อ-นามสกุล',
      accessor: 'kid.name',
      width: '10%',
    },
    {
      Header: 'ชื่อเล่น',
      accessor: 'kid.nickname',
      width: '10%',
    },
    {
      Header: 'Evaluation Fee',
      accessor: 'evaluaton',
      width: '10%',
      extra: (evaluaton) => {
        const evaluationFee = evaluaton?.payment?.transactions?.reduce(
          (acc, val) => (val?.action === 'paid' ? acc + Number(val.paid) : acc),
          0
        )
        return `${Number(evaluationFee).toLocaleString('th-TH')} บาท`
      },
    },
    {
      Header: 'Member Apply',
      width: '10%',
      accessor: 'logMember',
      extra: (logMember, row) =>
        logMember && logMember.isWaive
          ? 'Member Waive'
          : logMember && row?.status[0]
          ? `${Number(row?.status[0]?.payment[0]?.paid || 0).toLocaleString(
              'th-TH'
            )} บาท`
          : '-',
    },
    {
      Header: 'ยอดเงินจากการซื้อคอร์ส',
      accessor: 'enroll',
      width: '10%',
      extra: (enroll, row) => {
        const total = enroll.reduce((acc, e) => {
          const sumPaid = e.payment.reduce((acc, p) => acc + p.paid, 0)
          return acc + sumPaid
        }, 0)

        return row?.logMember
          ? `${Number(total).toLocaleString('th-TH')} บาท`
          : '-'
      },
    },
    {
      Header: 'ผู้ประเมิน',
      accessor: 'staff.nickname',
      width: '10%',
    },

    {
      Header: 'Action',
      accessor: 'kid',
      width: '10%',
      extra: (data, row) => (
        <Button
          variant='link'
          textDecoration='underline'
          onClick={() => {
            setModalData(row)
            onOpen()
          }}
        >
          รายละเอียด
        </Button>
      ),
    },
  ]
  const [searchQuery, setSearch] = useState({
    text: '',
    branch: managerBranchId || '',
    month: moment().format('YYYY-MM'),
  })
  const [modalData, setModalData] = useState()
  const [downloadIsLoading, setDownloadIsLoading] = useState(false)

  const { onOpen, isOpen, onClose } = useDisclosure()

  const { data: reportEvaluation, isLoading } = useQuery(
    ['evaluationReport', searchQuery?.month, searchQuery?.branch],
    async () => {
      const response = await getEvaluationReport({
        branchId: searchQuery?.branch,
        month: searchQuery?.month,
      })
      if (!response.data) {
        throw new Error('Network response was not ok')
      }
      return response.data
    },
    { refetchOnWindowFocus: false }
  )

  const [dataQuery, setDataQuery] = useState([])

  const sumEvaFee = useMemo(
    () =>
      dataQuery.reduce((sum, data) => {
        const evaluationFee = data?.evaluaton?.payment?.transactions?.reduce(
          (acc, val) => (val?.action === 'paid' ? acc + Number(val.paid) : acc),
          0
        )
        return sum + evaluationFee
      }, 0),
    [dataQuery]
  )

  const sumMemberFee = useMemo(
    () =>
      dataQuery.reduce((sum, data) => {
        if (data.logMember && data.status[0]) {
          return (
            sum +
            (data.status[0]?.payment[0]?.paid
              ? Number(data.status[0]?.payment[0]?.paid)
              : 0)
          )
        }
        return sum
      }, 0),
    [dataQuery]
  )

  useEffect(() => {
    if (reportEvaluation) {
      setDataQuery(reportEvaluation)
    }
  }, [reportEvaluation])

  async function handleDownloadFile(dataDownload) {
    setDownloadIsLoading(true)
    const mapTime = await dataDownload?.map((d, index) => {
      const sumEntrolltotal = d.enroll.reduce((acc, e) => {
        const sumPaid = e.payment.reduce((acc, p) => acc + p.paid, 0)
        return acc + sumPaid
      }, 0)

      return {
        assessmentDate: moment(d.date, 'YYYY-MM-DD').format('DD/MM/YYYY'), // วันประเมิน
        number: index + 1,
        kidName: d?.kid?.name ? d.kid.name : '', // ชื่อจริง
        kidNickname: d?.kid?.nickname ? d.kid.nickname : '', //  ชื่อเล่น
        birthday: d?.kid?.birthday
          ? moment(d?.kid?.birthday, 'YYYY-MM-DD').format('DD-MM-YYYY')
          : '', // วันเกิดเด็ก
        parent: d?.kid?.parentName1 ? d?.kid?.parentName1 : '', //ผู้ปกครอง
        telephone: d?.kid?.parentTel1 ? d?.kid?.parentTel1 : '', //เบอร์ผู้ปกครอง
        email: d?.kid?.kid?.parentEmail1 ? d?.kid?.parentEmail1 : '', //อีเมลล์ผู้ปกครอง
        developmentDiagnosis: d?.kid?.development?.diagnosis
          ? d?.kid?.development?.diagnosis
          : '', // ภาวะอาการ
        developmentDoctor: d?.kid?.development?.doctor
          ? d?.kid?.development?.doctor
          : '', //แพทย์ที่ดูแล
        knowKidsPlus: d?.kid?.from ? d?.kid?.from : '', //รู้จัก kid plus จาก
        evaluationFee: d?.program?.price ? d.program.price : '', // eve fee
        enrollment:
          d?.enroll?.length > 0
            ? d?.enroll
                .map((p, i) => `${i > 0 ? ', ' : ''} ${p?.program?.label}`)
                .join('')
            : 'Not Enroll',
        memberApply:
          d?.logMember && d?.logMember?.isWaive
            ? 'Member Waive'
            : d?.logMember && d.status[0]?.price
            ? d?.status[0]?.payment[0]?.paid || 0
            : '-', // member apply
        // memberApply:
        //   d?.logMember && d?.logMember.price
        //     ? d?.logMember.price
        //     : d?.logMember && d.status[0]?.price
        //     ? d.status[0]?.price
        //     : '', // member apply
        branch: d?.staff?.branch ? d?.staff?.branch : '', //สาขา
        assessor: d?.staff?.nickname ? d?.staff?.nickname : '', // ผู้ประเมิน
        entrollAmount: Number(Number(sumEntrolltotal).toFixed(2)),
      }
    })

    createFileExcelReportEveluation(JSON.stringify(mapTime))
      .then(async (i) => {
        const url = i.data.fileUrl
        const fileName = i.data.fileName
        await axios({
          url,
          method: 'GET',
          responseType: 'blob',
        })
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `${fileName}`)
            document.body.appendChild(link)
            link.click()
          })
          .catch((err) => {
            console.error(err)
            setDownloadIsLoading(false)
          })
        setDownloadIsLoading(false)
      })
      .finally(() => {
        setDownloadIsLoading(false)
      })
  }

  function handleSearch(e) {
    e.preventDefault()
    setSearch({ ...searchQuery, text: e.target.value })
    let textSearch = e.target.value
    const searchQueryArr = reportEvaluation
    const searchKeys = ['kid.name', 'kid.nickname']
    const results = search(searchQueryArr, searchKeys, textSearch)
    const searchResults = textSearch === '' ? searchQueryArr : results
    setDataQuery(searchResults)
  }

  return (
    <Box>
      {isOpen && (
        <ModalItems isOpen={isOpen} onClose={onClose} data={modalData} />
      )}
      <Text color='brand'>Search</Text>

      <form onSubmit={handleSearch}>
        <Box
          borderWidth='1px'
          borderColor='brand'
          borderRadius='10px'
          p={5}
          mb={5}
          mt={1}
        >
          <HStack
            spacing={{ base: 0, md: '20px' }}
            flexDirection={{ base: 'column', sm: 'column', md: 'row' }}
          >
            <FormControl>
              <FormLabel>Search Name</FormLabel>
              <Input
                id='search'
                type='search'
                onChange={(e) => handleSearch(e)}
                value={searchQuery.text}
                placeholder='Search Name'
              />
            </FormControl>
            <FormControl>
              <FormLabel>Branch</FormLabel>
              <Select
                id='branch'
                placeholder='Pleas Select branch'
                onChange={(e) =>
                  setSearch({ ...searchQuery, branch: e.target.value })
                }
                value={searchQuery.branch}
                isDisabled={managerBranchId}
              >
                {branches
                  .sort(
                    (a, b) =>
                      b.accountType.localeCompare(a.accountType) ||
                      new Date(b.createdDate) - new Date(a.createdDate)
                  )
                  .map((branch) => (
                    <option key={branch.id} value={branch.id}>
                      {branch.name}
                    </option>
                  ))}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Month</FormLabel>
              <DatePicker
                allowClear={false}
                picker='month'
                format={'MMMM YYYY'}
                defaultValue={moment(searchQuery.month)}
                onChange={(date) =>
                  setSearch({
                    ...searchQuery,
                    month: moment(date).format('YYYY-MM'),
                  })
                }
              />
            </FormControl>
            <HStack alignSelf={'end'}>
              <IconButton
                variant='outline'
                fontSize={'23px'}
                icon={<FaFileExport color='#8CC63E' />}
                onClick={() => {
                  handleDownloadFile(dataQuery)
                }}
                disabled={downloadIsLoading || !dataQuery.length > 0}
                isLoading={downloadIsLoading}
              />
            </HStack>
          </HStack>
        </Box>
      </form>
      {searchQuery.branch !== '' ? (
        <>
          <Stack
            my={5}
            p={{ base: 2, md: 5 }}
            borderWidth='1px'
            borderColor='brand'
            borderRadius='10px'
            w={{ base: 'full', lg: '35vw' }}
          >
            <HStack justifyContent='space-between'>
              <Heading size={{ base: 'sm', md: 'md' }}>Total Eva Fee</Heading>
              <Heading
                size={{ base: 'sm', md: 'md' }}
              >{`${sumEvaFee.toLocaleString()} บาท`}</Heading>
            </HStack>
            <HStack justifyContent='space-between'>
              <Heading size={{ base: 'sm', md: 'md' }}>
                Total Member Apply
              </Heading>
              <Heading
                size={{ base: 'sm', md: 'md' }}
              >{`${sumMemberFee.toLocaleString()} บาท`}</Heading>
            </HStack>
          </Stack>
          <Table
            columnsData={columnsData}
            tableData={dataQuery}
            noSearch
            loading={isLoading}
          />
        </>
      ) : (
        <Center>
          <Heading>Please Select Branch</Heading>
        </Center>
      )}
    </Box>
  )
}
